@import "base/normalize";

@import 'base/fonts';
@import 'base/vars';
@import 'base/mixins';
@import 'base/settings';

@import 'common/header';
@import 'common/subscribe';
@import 'common/team';
@import 'common/card';
@import 'common/button';
@import 'common/start';
@import 'common/directions';
@import 'common/benefits';
@import 'common/different';
@import 'common/common-photo';
@import 'common/diversity';
@import 'common/discover';
@import 'common/question';
@import 'common/footer';
@import 'common/socials';
@import 'common/menu';
@import 'common/lang-toggler';
@import 'common/menu-btn';
@import 'common/space';
@import 'common/formgroup';
@import 'common/images';
@import 'common/message';

@import 'home';
@import 'order';

body{
  &.no-scroll{
    overflow:hidden;
  }
}
