.space {
  overflow: hidden;
  &__container {
    height: 100%;
  }
  &__inner {
    height: 100%;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    &:after {
      content: '';
      display: block;
      padding-bottom: 62.2%;
    }
  }
  &__planet {
    position: absolute;
  }
  &__planet-1 {
    width: 40%;
    left: 0;
    top: 11%;
    z-index: 1;
  }
  &__planet-2 {
    width: 16%;
    right: 23.5%;
    top: 11%;
  }
  &__planet-3 {
    width: 12.2%;
    left: 44%;
    bottom: 5%;
    z-index: 1;
  }
  &__rocket {
    left: 46.2%;
    top: 22.5%;
    opacity: 0;
    will-change: transform;
    @include media(tablet-up) {
      top: 39%;
      left: 17.5%;
      width: 7.5%;
      transform: translate3d(0vw, 0vw, 0vw);
    }
    &.rocket-1 {
      top: 39%;
      left: 17.5%;
    }
    &.rocket-2 {
      top: 81%;
      left: 46.5%;
    }
    &.inited {
      transition: transform .1s linear;
      opacity: 1;
    }
  }
  @include media(mobile) {
    &__inner {
      margin-left: -23px;
      margin-right: -18px;
      overflow: hidden;
    }
    &__planet-1 {
      left: 7%;
      top: 7%;
      width: 38%;
    }
    &__planet-2 {
      bottom: 12%;
      top: auto;
      right: auto;
      width: 60%;
      left: 55%;
      z-index: 1;
    }
    &__planet-3 {
      display: none;
    }
    &__rocket {
      top: 13%;
      left: 16.5%;
      &.rocket-1 {
        top: 13%;
        left: 16.5%;
      }
      &.rocket-2 {
        top: 58%;
        left: 73.5%;
      }
    }
  }
  @include media(large-desktop-up) {
    &__inner {
      margin-left: -5%;
    }
  }
}
