.home {
  &__title {
    font-size: 9.3vw;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 40px;
    margin-left: -.7vw;
    margin-right: -.7vw;
    letter-spacing: 0.007em;
    white-space: nowrap;
    br {
      display: none;
    }
  }
  &__subscribe {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__promo-text {
    width: 50%;
    font-family: $garamond;
    font-style: italic;
    font-size: 30px;
    line-height: 1.2;
  }
  &__space {

  }
  &__team {
    margin-bottom: 92px;
  }
  &__start {
    background-color: #d71418;
    color: #ffffff;
    padding-top: 72px;
    padding-bottom: 70px;
  }
  &__directions {
    padding-top: 39px;
    padding-bottom: 155px;
  }
  &__benefits {
    padding-top: 50px;
  }
  &__different {
    padding-bottom: 76px;
  }
  &__photo {
    margin: 0 auto;
    max-width: 1302px;
  }
  &__diversity {
    padding-top: 115px;
  }
  &__discover {
    padding-bottom: 100px;
  }
  &__startfree {
    padding: 81px 0 65px;
    background-color: #f8e71c;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 22%;
      height: 100%;
      top: 0;
      left: 0;
      background: url(../images/pic-earth-piece.png) no-repeat;
      background-size: 185% auto;
      background-position: right top;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 25%;
      height: 100%;
      bottom: 0;
      right: 0;
      background: url(../images/pic-earth-piece-half.png) no-repeat;
      background-size: 100% auto;
      background-position: right bottom -70px;
    }
  }
  &__double {
    display: flex;
    flex-wrap: wrap;
  }
  @include media(large-desktop-up) {
    &__title {
      font-size: 9.94vw;
      padding-top: 0;
      padding-bottom: 60px;
    }
    &__startfree {
      &:before {
        background-size: 100% auto;
        right: 68%;
        left: auto;
        width: 24%;
      }
    }
  }
  @include media(mobile) {
    &__title {
      font-size: 52px;
      text-align: center;
      line-height: 1.1;
      margin-left: -10px;
      margin-right: -10px;
      padding-bottom: 25px;
      padding-top: 25px;
      br {
        display: block;
      }
    }
    &__subscribe {
      width: 100%;
    }
    &__promo-text {
      width: 100%;
      font-size: 20px;
      padding-top: 15px;
      line-height: 1.3;
      br {
        display: none;
      }
    }
    &__space {
      height: 430px;
    }
    &__team {
      margin-bottom: 32px;
    }
    &__start {
      padding-top: 50px;
      padding-bottom: 57px;
    }
    &__directions {
      padding-top: 32px;
      padding-bottom: 64px;
    }
    &__benefits {
      padding-top: 32px;
    }
    &__different {
      padding-bottom: 70px;
    }
    &__diversity {
      padding-top: 51px;
    }
    &__discover {
      padding-bottom: 64px;
    }
    &__startfree {
      padding: 53px 0 105px;
      &:before {
        content: none;
      }
      &:after {
        width: 100%;
        height: 100%;
        background-size: 100% auto;
        background-position: right -20px center;
      }
    }
  }
}
