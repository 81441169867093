.image {
  display: block;
  &--spaceman {
    bottom: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 126px;
    max-width: 100%;
  }
  &--rocket {
    bottom: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 187px;
    max-width: 100%;
  }
  &--no-prepayment {
    position: absolute;
    right: 65%;
    top: 75px;
    width: 28%;
  }
  &--greetings {
    position: absolute;
    left: 58%;
    top: 140px;
    width: 26%;
  }
  &--global-pool {
    position: absolute;
    top: 235px;
    right: 62.5%;
    width: 33.5%;
  }
  &--exclusive {
    position: absolute;
    left: 55%;
    top: 180px;
    width: 31.5%;
  }
  @include media(mobile) {

    &--spaceman {
      bottom: 30px;
      height: 180px;
      width: auto;
    }
    &--rocket {
      bottom: 30px;
      height: 150px;
      width: auto;
    }
    &--no-prepayment {
      position: relative;
      right: auto;
      top: 0;
      width: 100%;
      max-width: 320px;
      margin: 0 auto 22px;
    }
    &--greetings {
      position: relative;
      left: auto;
      top: 0;
      width: 100%;
      max-width: 320px;
      margin: 0 auto 15px;
    }
    &--global-pool {
      position: relative;
      right: auto;
      top: 0;
      width: 100%;
      max-width: 320px;
      margin: 0 auto 17px;
    }
    &--exclusive {
      position: relative;
      left: auto;
      top: 0;
      width: 100%;
      max-width: 320px;
      margin: 0 auto 28px;
    }
  }
}