.diversity {
  &__title {
    font-size: 60px;
    font-weight: 500;
    text-align: center;
  }
  &__desc {
    padding-top: 50px;
    padding-bottom: 125px;
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
  }
  &__img {
    max-width: 80%;
    display: block;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  @include media(mobile) {
    &__title {
      font-size: 30px;
    }
    &__desc {
      padding-top: 19px;
      padding-bottom: 90px;
    }
    &__img {
      max-width: 320px;
      width: 100%;
      margin-bottom: 11px;
    }
  }
}
