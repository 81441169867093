.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: .2s ease;
  &__bg {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.47);
    z-index: 1;
  }
  &__box {
    position: fixed;
    width: 80%;
    right: 0;
    top: 0;
    background-color: #ffffff;
    height: 100%;
    overflow: auto;
    padding: 52px 50px 30px 143px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translate3d(100%, 0, 0);
    transition: transform .7s ease;
    z-index: 2;
  }
  &__header {
    font-size: 16px;
    display: flex;
    margin-bottom: 140px;
    margin-right: 24%;
  }
  &__link {
    display: block;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: #D71418;
    }
  }
  &__send-cv {
    margin-left: 20%;
    visibility: hidden;
  }
  &__lang {
    margin-left: auto;
    visibility: hidden;
  }
  &__item {
    font-size: 30px;
    font-weight: 500;
    display: block;
    & + & {
      margin-top: 16px;
    }
    &:hover {
      color: $red;
    }
  }
  &__items {
    margin: 0 auto;
    width: 100%;
    max-width: 45.5em;
    position: relative;
    top: -40px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    padding: 0 15px 60px 10px;
  }
  &__contacts {
    font-size: 22px;
    line-height: 1.363;
    font-weight: 500;
  }
  &__logo {
    width: 27%;
    position: relative;
    img {
      display: block;
      max-width: 100%;
      width: 100%;


      @include media(large-desktop-up) {
      }
      @include media(desktop-up) {
      }
      @include media(tablet-up) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 80px;
      }
      @include media(mobile) {
        width: 80px;
      }
    }
  }
  &--active {
    visibility: visible;
    opacity: 1;
    .menu {
      &__box {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  @include media(mobile) {
    &__box {
      width: 100%;
      padding: 150px 22px 30px 22px;
      display: block;
      &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: $white;

      }
    }
    &__logo {
      position: fixed;
      left: 20px;
      top: 10px;
      bottom: auto;
      width: 220px;
      z-index: 1;
    }
    &__header {
      position: absolute;
      width: 100%;
      left: 22px;
      top: 445px;
      .menu__link {
        font-size: 16px;
      }
    }
    &__item {
      font-size: 20px;
      & + & {
        margin-top: 24px;
      }
    }
    &__items {
      padding-bottom: 50px;
      border-bottom: 1px solid black;
      position: static;
    }
    &__footer {
      padding: 0;
      margin-top: 20px;
    }
    &__link {
      font-size: 20px;
    }
    &__send-cv {
      margin-left: 45px;
    }
  }


}
