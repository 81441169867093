.header {
  &__inner {
    min-height: 120px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media(mobile) {
      min-height: 60px;
    }
  }
  &__logo {
    display: inline-block;
    width: 350px;
    img {
      display: block;
      max-width: 100%;
      @include media(tablet-up) {
        width: 79px;
      }
      @include media(mobile) {
        width: 35px;
      }
    }
  }
  &__burger {
    position: absolute;
    width: 86px;
    height: 86px;
    top: 22px;
    right: 7px;
    z-index: 11;
    &.fixed {
      position: fixed;
    }
    @include media(mobile) {
      width: 40px;
      height: 40px;
      top: 10px;
      right: 10px;
    }
  }
  @include media(large-desktop-up) {
    &__burger {
      right: 30px;
    }
  }

}
