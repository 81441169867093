.card {
  &__img {
    position: relative;
    height: 263px;
  }
  &__icon {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 172px;
    height: 172px;
    margin-bottom: 40px;
    border-radius: 50%;
    border: 2px solid #fff;
    img {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &.pic-girl-search {
        height: 82%;
        transform: translate(-51%,-48%);
      }
      &.pic-badge {
        height: 82%;
        transform: translate(-50%,-48%);
      }
      &.pic-trajectory {
        height: 61%;
        transform: translate(-49%,-55%);
      }
    }
  }
  &__text {
    font-size: 22px;
    line-height: 30px;
    font-family: $futuranew;
  }
  &__title {
    font-family: $futuranew;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.25;
    min-height: 125px;
  }
  &--benefit {
    .card {
      &__title {
        min-height: auto;
        margin-bottom: 35px;
      }
      &__text {
        max-width: 22em;
      }
    }
  }
  @include media(mobile) {
    &__text {
      font-size: 16px;
      line-height: 1.3;
    }
    &__img {
      height: 216px;
    }
    &__icon {
      width: 122px;
      height: 122px;
      margin-bottom: 10px;
    }
    &__title {
      font-size: 30px;
      text-align: center;
      min-height: auto;
      margin-bottom: 14px;
    }
    &--benefit {
      .card {
        &__text {
          max-width: none;
        }
        &__title {
          margin-bottom: 10px;
        }
      }
    }
    &--team {
      .card__text {
        font-size: 14px;
        line-height: 1.3;
      }
    }
    &--no-prepayment {

    }
    &--it-specific {

    }
    &--global {

    }
    &--exclusive {

    }
  }
}





