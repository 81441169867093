.footer {
  padding-top: 36px;
  padding-bottom: 32px;
  color: #ffffff;
  background-color: #d71418;
  &__inner {
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    @include media(desktop-up) {
      width: 1100px;
      max-width: 100%;
    }
  }
  &__top-row {
    min-height: 115px;
  }
  &__row {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
  }
  &__logo-box {
    flex-basis: 50%;
    padding: 0 15px;
  }
  &__logo {
    width: 30%;

    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.8;
    }

    img {
      max-width: 100%;
      width: 110px;
      @include media(mobile) {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__contacts {
    flex-basis: 50%;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.363;
  }
  &__contact-link {
    display: block;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
  &__socials {
    padding: 0 15px;
    flex-basis: 50%;
  }
  &__addresses {
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__city {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__address {
    font-size: 16px;
    line-height: 1.25;
    width: 37%;
  }
  &__copyright {
    position: absolute;
    left: 12px;
    bottom: 0;
    font-size: 13px;
  }
  @include media(mobile) {
    &__logo-box {
      flex-basis: 100%;
      margin-bottom: 60px;
    }
    &__contacts {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 60px;
    }
    &__socials {
      flex-basis: 100%;
      text-align: center;
    }
    &__addresses {
      flex-basis: 100%;
      padding-left: 20px;
    }
    &__address {
      width: 100%;
      margin-bottom: 37px;
    }
    &__copyright {
      position: static;
      text-align: center;
    }
  }
}


