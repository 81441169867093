.order {
  display:none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  padding-bottom: 100px;
  background: #fff;

  &__container {
    @extend .container;
    padding-top: 63px;
    @include media(mobile) {
      padding-top: 36px;
    }
  }

  &__header {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 54px;
  }

  &__form-box {
    margin: 0 auto;
    max-width: 410px;
  }

  &__input {
    width: 100%;
    padding: 4px 10px 6px 0;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid #000000;
    outline: none;
    &::placeholder {
      color: #9b9b9b;
    }
    &.error {
      border-color: $tomato;
      color: $red;
      &::placeholder {
        color: $red;
      }
    }
  }

  &__formgroup {
    width: 100%;
    margin-top: 30px;
    position: relative;
  }

  &__btn-box {
    text-align: center;
  }

  &__btn {
    width: 190px;
    display: inline-block;
  }

  &__btn-send {
    margin-top: 39px;
  }

  &__btn-back {
    margin-top: 20px;
  }

  .error {
    display: block;
    color: $red;
    font-weight: 500;
    margin-top: 0.5em;
  }

  .success {
    text-align: center;
    color: $green;
    font-weight: 500;
    margin-top: 24px;
    @include media(mobile) {
      margin-top: 20px;
    }
  }
}
