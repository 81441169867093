.menu-btn {
  background-color: $red;
  border-radius: 50%;
  cursor: pointer;
  &__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 41%;
    height: 26%;
  }
  &__line {
    position: absolute;
    background-color: #ffffff;
    height: 2px;
    width: 100%;
    left: 0;
    transition: .25s ease-in-out;
    &-1 {
      top: 0;    
    }
    &-2 {
      top: 50%;
      transform: rotate(0deg);
    }
    &-3 {
      top: 50%;
      transform: rotate(0deg);
    }
    &-4 {
      top: 100%;
    }
  }
  &--close {
    .menu-btn {
      &__line {
        &-1 {
          top: 50%;
          width: 0%;
          left: 50%;
        }
        &-2 {
          transform: rotate(45deg);
        }
        &-3 {
          transform: rotate(-45deg);
        }
        &-4 {
          top: 50%;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
}