.subscribe {
  &__title {
    font-size: 22px;
    font-family: $futuranew;
    font-weight: 500;
    margin-bottom: 15px;
  }
  &__input {
    font-size: 22px;
    font-family: $futuranew;
    font-weight: 500;
    padding: .55em .7em .75em .7em;
    border: 2px solid #d0021b;
    width: 100%;
    &::placeholder {
      color: #d71418;
    }
    &.error {
      color: $red;
    }
  }
  &__btn {
    display: block;
    min-width: 55px;
    border: 2px solid #d0021b;
    border-left: none;
    background: url(../images/icon-send.svg) no-repeat center;
    background-size: 78% 78%;
    background-color: #d0021b;
    margin-left: -2px;
    position: relative;
    z-index: 1;

    transition: background-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &:hover {
      background-color: #000;
      border-color: #000;
    }
  }
  &__group {
    display: flex;
    margin-bottom: 13px;
    width: 66%;
    @include media(mobile) {
      width: 100%;
    }
  }
  &__form {
    display: block;
    position: relative;
    .success {
      position: absolute;
      color: $green;
      font-weight: 500;
      left: 4px;
      top: 110%;
    }
  }
  &__formgroup {
    flex-grow: 1;
    position: relative;
    label.error {
      position: absolute;
      color: $red;
      font-weight: 500;
      left: 4px;
      top: 110%;
    }
  }
}
