//fonts
$garamond: 'Garamond', Helvetica, Arial, sans-serif;
$futuranew: 'FuturaNew', Helvetica, Arial, sans-serif;

//colors
$red: #d71418;
$red2: #d0021b;
$white: #ffffff;
$black: #000000;
$gray: #9b9b9b;
$tomato: #ff6347;
$green: #009640;