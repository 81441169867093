@font-face {
    font-family: 'Garamond';
    src: url('../fonts/Garamond-Premier-Pro-Italic-Caption_19572.woff2') format('woff2'),
         url('../fonts/GaramondPremrPro-ItCapt.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'FuturaNew';
    src: url('../fonts/FuturaNewMedium-Reg.woff2') format('woff2'),
         url('../fonts/FuturaNewMedium-Reg.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaNew';
    src: url('../fonts/FuturaNewBold-Reg.woff2') format('woff2'),
         url('../fonts/FuturaNewBold-Reg.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaNew';
    src: url('../fonts/FuturaNewBook-Reg.woff2') format('woff2'),
         url('../fonts/FuturaNewBook-Reg.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

