.team {
  &__purpose {
    text-align: center;
    font-family: $futuranew;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    margin: 0 auto 30px;
    max-width: 28em;
  }
  &__items {
    display: flex;
    max-width: 800px;
    width: 95%;
    margin: 0 auto;
  }
  &__item {
    width: 50%;
    padding: 0 5% 0 5%;
  }
  @include media(mobile) {
    &__purpose {
      font-size: 20px;
      line-height: 1.3;
      width: auto;
      margin-left: -7px;
      margin-right: -7px;
    }
    &__items {
      justify-content: space-between;
      width: 100%;
    }
    &__item {
      padding: 0;
      width: 46%;
    }
  }
}