@mixin media($val){
    @if $val == 'mobile' {
      @media screen and (max-width: 700px) {
        @content;
      }
    } @else if $val == 'desktop-up' {
      @media screen and (min-width: 1111px) {
        @content;
      }
    } @else if $val == 'large-desktop-up' {
      @media screen and (min-width: 1301px) {
        @content;
      }
    } @else if $val == 'tablet-up' {
      @media screen and (min-width: 701px) {
        @content;
      }
    } 
}
