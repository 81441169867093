.message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  visibility: hidden;
  text-align: center;
  opacity: 0;
  transition: .2s ease;
  transform: translate3d(0, -100%, 0);
  font-size: 14px;
  font-weight: 500;
  padding: 23px;
  color: $white;
  &--visible {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &--error {
    background-color: $red;
    &:after {
      content: attr(data-error);
    }
  }
  &--success {
    background-color: $green;
    &:after {
      content: attr(data-success);
    }
  }
}
