* {
    box-sizing: border-box;
}

:after, :before {
    box-sizing: border-box;
}

.container {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    @include media(desktop-up) {
        padding-right: 15px;
        padding-left: 15px;
        width: 1210px;
        max-width: 100%;
    }
    @include media(mobile) {
        padding-left: 20px;
        padding-right: 18px;
    }
    @include media(large-desktop-up) {
        &--fullscreen {
            width: auto;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

body {
    background-color: #ffffff;
    color: #000000;
    font-family: $futuranew;
    position: relative;
    &.overflow-hidden {
        overflow: hidden;
    }
}

a, button {
    cursor: pointer;

}

input {
    border-radius: 0;

    &:focus {
        outline: none;
    }
    &::placeholder {
        color: inherit;
    }
}

button {
    &:focus {
        outline: none;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

h1, h2, h3, h4 {
    font-weight: inherit;
    font-size: inherit;
    margin: 0;
    display: inline;
}
