.socials {
  &__item {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: 0.4s transform;
    & + & {
      margin-left: 40px;
    }
  }
}

.social {
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
  transition: 0.4s transform;
  &:hover {
    transform: scale(1.05);
  }
  &--fb {
    background-image: url(../images/social-fb.svg);
  }
  &--ha {
    background-image: url(../images/social-ha.svg);
  }
}
