.start {
  &__title {
    width: 95%;
    max-width: 25em;
    line-height: 1.2;
    font-size: 30px;
    font-family: $futuranew;
    font-weight: 700;
    text-align: center;
    margin: 0 auto 30px;
    position: relative;
    z-index: 1;
  }
  &__btn-box {
    text-align: center;
  }
  &__text {
   font-size: 22px;
   line-height: 1.363;
   text-align: center;
   margin: 40px auto 0;
   width: 100%;
   max-width: 18em;
   position: relative;
   z-index: 1;
  }
  &__btn {
    display: inline-block;
    position: relative;
    z-index: 1;
  }
  &--free {
    .start__title {
      margin-bottom: 50px;
    }
  }
  @include media(mobile) {
    &__title {
      font-size: 20px;
      line-height: 1.3;
      margin-bottom: 44px;
    }
    &__text {
      font-size: 20px;
      font-weight: 500;
      max-width: 13em;
      margin-top: 30px;
    }
  }
}
