.different {
  text-align: center;
  &__title {
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 49px;
  }
  &__promo {
    font-size: 60px;
    font-weight: 500;
    margin: 0 auto;
    width: 100%;
    max-width: 15em;
    padding-bottom: 55px;
  }
  &__text {
    font-size: 22px;
    line-height: 1.363;
    font-weight: 700;
    margin: 0 auto;
    width: 100%;
    max-width: 29em;
  }
  @include media(mobile) {
    &__title {
      font-size: 20px;
      margin: 0 auto;
      max-width: 12em;
      line-height: 1.3;
      padding-bottom: 21px;
    }
    &__promo {
      font-size: 30px;
      max-width: 9em;
      line-height: 1.2;
      padding-bottom: 24px;
    }
    &__text {
      font-size: 20px;
      line-height: 1.3;
      margin-left: -10px;
      margin-right: -10px;
      width: auto;
    }
  }
}
