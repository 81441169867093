.discover {
  &__title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 57px;
    br {
      display: none;
    } 
  }
  &__items {
    margin: 0 auto 55px;
    width: 100%;
    max-width: 630px;
  }
  &__btn-box {
    text-align: center;
  }
  &__item {
    margin-bottom: 21px;
  }
  &__btn {
    display: inline-block;
  }
  @include media(mobile) {
    &__title {
      font-size: 30px;
      font-weight: 500;
      line-height: 1.3;
      padding-bottom: 35px;
      br {
        display: inline-block;
      }
    }
    &__items {
      width: 85%;
      margin-bottom: 49px;
    }
    
  }
}
