.question {
  border-bottom: 1px solid gray;
  &__text {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 20px;
    cursor: pointer;
    color: $black;
    transition: color .3s ease;
    &:hover {
      color: $gray;
    }
  }
  &__answer {
    font-size: 16px;
    padding-bottom: 40px;
    display: none;
  }
  &--opened {
    .question__text {
      color: $gray;
    }

  }
}
