.common-photo {
  position: relative;
  background: url(../images/photo-team.jpg) no-repeat center/cover;
  &:after {
    content: '';
    display: block;
    padding-bottom: 46.1%;
  }
  &__title {
    position: absolute;
    width: 100%;
    left: 0;
    top: 25px;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
  &__btn-box {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 30px;
    text-align: center;
  }
  &__btn {
    display: inline-block;
  }
  @include media(mobile) {
    background-size: auto 68%;
    background-position: center bottom 70px;
    &__title {
      position: absolute;
      font-size: 20px;
      top: auto;
      left: 0;
      color: $black;
    }
    &__btn-box {
      bottom: 0;
    }
    &:after {
      padding-bottom: 110.5%;
    }
  }
  @include media(large-desktop-up){
    &:after {
      padding-bottom: 47.5%;
    }
  }
}
